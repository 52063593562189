import React from 'react';

const ConsultationPlans = () => {
  const plans = [
    {
      title: 'Basic Plan',
      price: '₹300',
      features: ['Consultation for beginners', 'Basic market analysis', 'Email support'],
    },
    {
      title: 'Standard Plan',
      price: '₹600',
      features: ['In-depth market analysis', 'Customized trading strategies', 'Phone and email support'],
    },
    {
      title: 'Premium Plan',
      price: '₹1000',
      features: ['Advanced trading techniques', 'One-on-one mentoring', '24/7 support'],
    },
    {
      title: 'Elite Plan',
      price: '₹1500',
      features: ['All-inclusive training', 'Dedicated account manager', 'Priority support'],
    },
  ];

  return (
    <div className="container mx-auto px-6 md:px-12 lg:px-24 py-12">
      <h1 className="text-4xl font-bold mb-12 text-center text-blue-700">Trading Consultation Plans</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {plans.map((plan, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-lg border border-blue-200 hover:shadow-xl transition-shadow duration-300">
            <h2 className="text-2xl font-semibold text-blue-600 mb-4 text-center">{plan.title}</h2>
            <p className="text-3xl font-bold text-blue-800 mb-6 text-center">{plan.price}</p>
            <ul className="text-gray-700 mb-6">
              {plan.features.map((feature, idx) => (
                <li key={idx} className="mb-2 flex items-center">
                  <svg className="w-5 h-5 text-blue-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-11.707a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            <button className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition-colors duration-300">
              Choose Plan
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConsultationPlans;
