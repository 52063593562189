import React from 'react';

const OverviewWithImage = () => {
  return (
    <div className="container mx-auto px-6 md:px-12 lg:px-24 py-12 flex flex-col md:flex-row">
      <div className="w-full md:w-1/2 bg-white shadow-md rounded-md p-6 overflow-y-auto max-h-96">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-700">SkytechAlgo Overview</h1>
        <p className="text-gray-700 mb-4">
          SkytechAlgo is a listed public company incorporated on 15 March, 2010. It is classified as a public limited company and is located in Mumbai, Maharashtra. The authorized share capital is INR 50.00 cr and the total paid-up capital is INR 10.00 cr.
        </p>
        <p className="text-gray-700 mb-4">
          SkytechAlgo's operating revenues range is INR 5 cr - 50 cr for the financial year ending on 31 March, 2023. Its EBITDA has increased by 12.45% over the previous year. At the same time, its book net worth has increased by 5.67%. Other performance and liquidity ratios are available here.
        </p>
        <p className="text-gray-700 mb-4">
          <span className="font-bold">Description:</span> The company engages in advanced algorithmic trading activities in India. It operates through multiple segments, including Algorithmic Trading, Financial Consulting, and Technology Solutions.
        </p>
        <p className="text-gray-700 mb-4">
          <span className="font-bold">Products & Services:</span> SkytechAlgo offers a range of products and services including algorithmic trading strategies, high-frequency trading systems, financial consulting, and custom technology solutions for trading and investment. The company also trades in equities, debt instruments, mutual funds, and derivatives including options and futures.
        </p>
        <p className="text-gray-700 mb-4">
          <span className="font-bold">Category:</span> Service Provider
        </p>
        <p className="text-gray-700 mb-4">
          The current status of SkytechAlgo is <span className="font-bold">Active</span>.
        </p>
        <p className="text-gray-700 mb-4">
          The last reported AGM (Annual General Meeting) of SkytechAlgo, per our records, was held on 30 September, 2023.
        </p>
        <p className="text-gray-700 mb-4">
          SkytechAlgo has a strong board of directors led by experienced professionals in the finance and technology sectors.
        </p>
        <p className="text-gray-700 mb-4">
          The Corporate Identification Number (CIN) of SkytechAlgo is L12345MH2010PLC123456. The registered office of SkytechAlgo is at 101, Tech Park, Bandra Kurla Complex, Mumbai, Maharashtra.
        </p>
      </div>
      <div className="w-full md:w-1/2 flex items-center justify-center p-6">
        <img src="https://via.placeholder.com/400x300" alt="Graph or Chart" className="rounded-md shadow-md" />
      </div>
    </div>
  );
};

export default OverviewWithImage;
