import React from 'react';

const TopicsInfoComponent = () => {
  return (
    <div className="container mx-auto p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        
        {/* Investing */}
        <div className="bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold text-blue-600 mb-4">Investing</h2>
          <p className="text-gray-700 mb-4">
            Investing involves committing money or capital to an endeavor with the expectation of obtaining an 
            additional income or profit. This can be done through various means such as stocks, bonds, real estate, 
            or mutual funds. The main objective is long-term growth and capital appreciation.
          </p>
          <h3 className="text-lg font-semibold mb-2">Examples:</h3>
          <ul className="list-disc pl-5">
            <li>Buying shares of a company expecting the stock price to rise over the years.</li>
            <li>Purchasing real estate properties to earn rental income and potential appreciation in value.</li>
            <li>Investing in mutual funds that are diversified across different sectors.</li>
          </ul>
        </div>

        {/* Trading */}
        <div className="bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold text-blue-600 mb-4">Trading</h2>
          <p className="text-gray-700 mb-4">
            Trading involves more frequent transactions, such as the buying and selling of stocks, commodities, currency pairs, or other instruments. The goal is to generate returns that outperform buy-and-hold investing.
          </p>
          <h3 className="text-lg font-semibold mb-2">Examples:</h3>
          <ul className="list-disc pl-5">
            <li>Day trading where stocks are bought and sold within the same trading day.</li>
            <li>Swing trading which involves holding positions for several days or weeks to profit from expected price movements.</li>
            <li>Scalping, a strategy that involves profiting from small price changes and making multiple trades each day.</li>
          </ul>
        </div>

        {/* Futures Options */}
        <div className="bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold text-blue-600 mb-4">Futures Options</h2>
          <p className="text-gray-700 mb-4">
            Futures options give the buyer the right, but not the obligation, to buy or sell a futures contract at a specific price before the option expires. They are used by traders to hedge against risks or speculate on the future prices of assets.
          </p>
          <h3 className="text-lg font-semibold mb-2">Examples:</h3>
          <ul className="list-disc pl-5">
            <li>Buying a call option on a crude oil futures contract, expecting the price of oil to rise.</li>
            <li>Purchasing a put option on a wheat futures contract to hedge against potential price declines in wheat.</li>
            <li>Using futures options to lock in prices for raw materials needed for manufacturing.</li>
          </ul>
        </div>

        {/* Intraday Trading */}
        <div className="bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold text-blue-600 mb-4">Intraday Trading</h2>
          <p className="text-gray-700 mb-4">
            Intraday trading, also known as day trading, involves buying and selling financial instruments within the same trading day. Traders take advantage of small price movements to earn profits multiple times throughout the day.
          </p>
          <h3 className="text-lg font-semibold mb-2">Examples:</h3>
          <ul className="list-disc pl-5">
            <li>Buying a stock when its price is low in the morning and selling it when the price rises in the afternoon.</li>
            <li>Using technical analysis to identify intraday trading opportunities based on chart patterns.</li>
            <li>Engaging in high-frequency trading, where traders use algorithms to execute a large number of orders at high speeds.</li>
          </ul>
        </div>

      </div>
    </div>
  );
};

export default TopicsInfoComponent;
