import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-blue-900 text-gray-300 py-8">
      <div className="container mx-auto px-6 md:px-12 lg:px-24">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <h2 className="text-2xl font-bold text-yellow-400 mb-4">TradingApp</h2>
            <p className="text-sm mb-4">
              TradingApp is a leading platform for trading stocks, commodities, and currencies. Our mission is to 
              provide seamless and secure trading experiences for traders of all levels. Join us to explore the 
              opportunities in the financial markets with confidence and efficiency.
            </p>
            <p className="text-sm">
              123 Trading St., Financial District<br />
              City, Country 12345<br />
              Email: <a href="mailto:support@tradingapp.com" className="hover:text-yellow-300">support@tradingapp.com</a><br />
              Phone: <a href="tel:+1234567890" className="hover:text-yellow-300">+123-456-7890</a>
            </p>
          </div>
          
          {/* Quick Links */}
          <div>
            <h3 className="text-xl font-semibold text-yellow-400 mb-4">Quick Links</h3>
            <ul>
              <li className="mb-2"><a href="/" className="hover:text-yellow-300">Home</a></li>
              <li className="mb-2"><a href="/about" className="hover:text-yellow-300">About Us</a></li>
              <li className="mb-2"><a href="/services" className="hover:text-yellow-300">Services</a></li>
              <li className="mb-2"><a href="/contact" className="hover:text-yellow-300">Contact</a></li>
              <li className="mb-2"><a href="/faq" className="hover:text-yellow-300">FAQ</a></li>
            </ul>
          </div>

          {/* Resources */}
          <div>
            <h3 className="text-xl font-semibold text-yellow-400 mb-4">Resources</h3>
            <ul>
              <li className="mb-2"><a href="/blog" className="hover:text-yellow-300">Blog</a></li>
              <li className="mb-2"><a href="/webinars" className="hover:text-yellow-300">Webinars</a></li>
              <li className="mb-2"><a href="/guides" className="hover:text-yellow-300">Guides</a></li>
              <li className="mb-2"><a href="/tools" className="hover:text-yellow-300">Trading Tools</a></li>
              <li className="mb-2"><a href="/news" className="hover:text-yellow-300">Market News</a></li>
            </ul>
          </div>

          {/* Legal */}
          <div>
            <h3 className="text-xl font-semibold text-yellow-400 mb-4">Legal</h3>
            <ul>
              <li className="mb-2"><a href="/privacy" className="hover:text-yellow-300">Privacy Policy</a></li>
              <li className="mb-2"><a href="/terms" className="hover:text-yellow-300">Terms of Service</a></li>
              <li className="mb-2"><a href="/disclaimer" className="hover:text-yellow-300">Disclaimer</a></li>
              <li className="mb-2"><a href="/security" className="hover:text-yellow-300">Security</a></li>
              <li className="mb-2"><a href="/cookies" className="hover:text-yellow-300">Cookies Policy</a></li>
            </ul>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="mt-8 text-center">
          <h3 className="text-xl font-semibold text-yellow-400 mb-4">Follow Us</h3>
          <div className="flex justify-center space-x-6">
            <a href="https://facebook.com" className="hover:text-yellow-300"><i className="fab fa-facebook-f"></i> Facebook</a>
            <a href="https://twitter.com" className="hover:text-yellow-300"><i className="fab fa-twitter"></i> Twitter</a>
            <a href="https://linkedin.com" className="hover:text-yellow-300"><i className="fab fa-linkedin-in"></i> LinkedIn</a>
          </div>
        </div>

        <div className="mt-8 text-center border-t border-gray-700 pt-4">
          <p className="text-sm">
            &copy; 2024 TradingApp. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
