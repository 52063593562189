import './App.css';
import Layout from './pages/layout/Layout';
import AllRoutes from './routes/AllRoutes';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <div className="App">
        <Layout>
          <AllRoutes/>
        </Layout>
    </div>
  );
}

export default App;
