import React from 'react'
import TradingInfoComponent from '../../components/TradingInfoComponent'
import TopicsInfoComponent from '../../components/TopicsInfoComponents'
import ConsultationPlans from '../../components/TradingConsultationPlans'
import OverviewComponent from '../../components/OverviewComponent'

const Home = () => {
  return (
    <div>
      <TradingInfoComponent/>
      <ConsultationPlans/>
      <TopicsInfoComponent/>
      <OverviewComponent/>
    </div>
  )
}

export default Home