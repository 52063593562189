import React from 'react';

const TradingInfoComponent = () => {
  return (
    <div className="flex flex-col gap-4 md:flex-row bg-gray-100 p-6">
      {/* Left Side: Information */}
      <div className="w-full md:w-1/2 space-y-6">
        <div className="bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold mb-2 text-blue-600">What is Trading?</h2>
          <p className="text-gray-700">
            Trading involves buying and selling financial instruments like stocks, bonds, commodities, or currencies 
            with the aim of making a profit. The financial markets where trading occurs can be highly dynamic and 
            complex, influenced by a myriad of factors including economic data, company performance, geopolitical 
            events, and market sentiment.
          </p>
        </div>
        <div className="bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold mb-2 text-blue-600">Market Analysis</h2>
          <p className="text-gray-700">
            A key aspect of successful trading is understanding market trends and patterns. Technical analysis, which 
            studies price movements and chart patterns, is commonly used by traders to forecast future price movements. 
            Fundamental analysis, on the other hand, involves evaluating the intrinsic value of an asset by examining 
            related economic, financial, and other qualitative and quantitative factors.
          </p>
        </div>
        <div className="bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold mb-2 text-blue-600">Risk Management</h2>
          <p className="text-gray-700">
            Risk management is another crucial element in trading. Effective risk management involves setting stop-loss 
            orders to limit potential losses and diversifying one's portfolio to mitigate risk. It's important to have 
            a clear trading plan and to stick to it, avoiding emotional decision-making which can lead to significant 
            financial losses.
          </p>
        </div>
        <div className="bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold mb-2 text-blue-600">Trading Strategies</h2>
          <p className="text-gray-700">
            There are different trading strategies tailored to various market conditions and individual preferences. 
            Day trading involves making multiple trades within a single day, capitalizing on short-term market 
            movements. Swing trading, on the other hand, seeks to capture gains over a few days to weeks. Long-term 
            investing involves holding onto investments for several months or years, benefiting from compounding 
            returns and fundamental growth of the asset.
          </p>
        </div>
        <div className="bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold mb-2 text-blue-600">Role of Technology</h2>
          <p className="text-gray-700">
            Technology plays a vital role in modern trading. Trading platforms provide traders with tools to execute 
            trades, analyze markets, and manage their accounts efficiently. Algorithmic trading, which uses computer 
            algorithms to automate trading strategies, has become increasingly popular. It allows for high-frequency 
            trading and the ability to execute complex strategies at speeds impossible for human traders.
          </p>
        </div>
        <div className="bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold mb-2 text-blue-600">Continuous Learning</h2>
          <p className="text-gray-700">
            As the trading landscape evolves, it is essential for traders to continuously educate themselves and stay 
            updated with the latest market developments and technological advancements. Participating in trading 
            communities, attending webinars, and reading financial news are effective ways to enhance trading knowledge 
            and skills.
          </p>
        </div>
      </div>

      {/* Right Side: Images */}
      <div className="w-full md:w-1/2 flex flex-col space-y-4">
        <img src="https://cdn.pixabay.com/photo/2016/12/13/22/15/chart-1905224_640.jpg" alt="Trading Chart" className="rounded-md shadow-md"/>
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsI-FOYU34DXSGMSmSzV6FhU9iZtCST3WzgA&usqp=CAU" alt="Stock Market" className="rounded-md shadow-md"/>
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaBJ6udOiyhHoowuL5fWfnqtjY1ehGx2F1wA&usqp=CAU" alt="Trading Chart" className="rounded-md shadow-md"/>

      </div>
    </div>
  );
};

export default TradingInfoComponent;
